



























































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { applicationStoreGetter } from '@/store/application.store';
import Company from '@/models/Company';
import { driverStoreActions, driverStoreGetter } from '@/store/driver.store';
import Driver from '@/models/Driver';
import moment from 'moment/moment';

const ApplicationStore = namespace('application');
const DriverStore = namespace('driver');

@Component({
    components: {
        DriverTableComponent: () => import(
            /* webpackChunkName: "DriverTableComponent" */
            '@/components/DriverTable.component.vue'),
        EditDriverComponent: () => import(
            /* webpackChunkName: "EditDriverComponent" */
            '@/components/EditDriver.component.vue')
    }
})
export default class DriversView extends Vue {
    @ApplicationStore.Getter(applicationStoreGetter.SELECTED_COMPANY)
    public selectedCompany!: Company;

    @DriverStore.Action(driverStoreActions.LIST)
    public loadAllDrivers!: (payload: { company: Company, from: string, to: string }) => Promise<Driver[]>;

    @DriverStore.Getter(driverStoreGetter.DRIVERS)
    public allDrivers!: Driver[];

    public startMenu: boolean = false;
    public endMenu: boolean = false;
    public startDate: string | null = null;
    public endDate: string | null = null;

    public isLoading: boolean = false;

    public searchValue: string = '';
    public showCreationDialog: boolean = false;

    public async created() {
        const today = moment(new Date());
        this.endDate = today.format('yyyy-MM-DD');
        today.set({ date: 1 });
        this.startDate = today.format('yyyy-MM-DD');
        await this.loadDrivers();
    }

    public async closeCreationDialog(reload: boolean) {
        this.showCreationDialog = false;
        if (reload) {
            await this.loadDrivers();
        }
    }

    private async loadDrivers(): Promise<void> {
        try {
            this.isLoading = true;
            await this.loadAllDrivers({ company: this.selectedCompany, from: this.startDate!, to: this.endDate! });
        } catch (e) {
            console.log(e);
        } finally {
            this.isLoading = false;
        }
    }
}
